import loadable from '@loadable/component';
import React, { memo } from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom'
const IsolatedSearchBar = loadable(() => import('../../common/IsolatedSearchBar/IsolatedSearchBar'));
import SingleSearchBar from '../SingleSearchBar/SingleSearchBar';
import './FeatureDetailsWithSearchbar.css'
import HomePageCardComponent from "../HomePageCard/HomePageCardComponent"
import DynamicSvg from '../../DynamicSvg';

const FeatureDetailsWithSearchbar = memo(function FeatureDetailsWithSearchbar(props) {
    const {
        componentName = "",
        singleSearchComp = {},
        heading = "",
        headingWithIcon = "",
        paragraphs = [],
        alignment = "",
        fontColorClass = "",
        linkNote = {},
        textNote = "",
        headingSize = "",
        headingTag = "h1",
        informationSectionData = {},
        headingDecoration=false,
        searchbarDividerLine = false,
        imageUrl={},
        multipleSearch=true,
        buttonComp=[],
        homePageCardComp={}
    } = props;

    const {
        singleSearchBarId = "",
        singleSearchBarType = "",
        setSearchTextboxValue = "",
        searchButtonPlaceholder = "",
        searchTextboxValue = "",
        eventCreatedKeyDown = "",
        eventTypeKeyDown = "",
        eventPage = "",
        eventTypeSearch = "",
        eventCreatedSearch = "",
        eventCreatedOptionClick = "",
        eventCreatedIconClick = "",
        singleSearchTypes = [],
        industry = "",
        btnLink = "",
        userType = "",
        showDropDownTextOnly = false,
        modalHeading = "",
        modelCssClass = "",
        dropDownOptionOnClick = "",
        mediaEqualVersionType,
        disableSearchIconClick,
        applyLiveDormantFilter = false,
        DropdownItemTag = "",
        optionOnClick = () => {},
        dropdownItemClassName = "",
        showSearchText="",
        inputBeforeIcon=""
    } = singleSearchComp;

    const getAltAttribute = (imagePath) => {
        if (!imagePath) {
            return "";
        }
        const imageName = imagePath.split("/");
        const altTagAttribute = imageName[imageName.length - 1].split(".")[0];
        return `${altTagAttribute}_image`;
    }
    const alignmentClass = alignment === "center" ? "center-align-comp" : ""
    const headingClass = headingSize === "big" ? "feature-details-with-searchbar-big-heading"
        : headingSize === "medium" ? "feature-details-with-searchbar-medium-heading"
            : headingSize === "small" ? "feature-details-with-searchbar-small-heading"
                : "feature-details-with-searchbar-medium-heading";
    const HeadingTag = headingTag || "h1";
    
    const CustomMediaQuery = ({ children }) => {
        let renderedUi = null;
        if(imageUrl?.mediaQueryParamImg === "desktop") {
            renderedUi = <MediaQuery minWidth={992}>{children}</MediaQuery>       
        } else {
            renderedUi = <>{children}</>       
        }
        return renderedUi;
    }
    return (
        <div id={componentName} className={`feature-details-with-searchbar ${alignmentClass}`}>
            {headingWithIcon ? <HeadingTag className={`feature-details-with-searchbar-heading ${fontColorClass} ${headingClass}`} >{heading}{headingWithIcon}</HeadingTag>
            : <HeadingTag className={`feature-details-with-searchbar-heading ${fontColorClass} ${headingClass}`} dangerouslySetInnerHTML={{ __html: heading }} />}
            {headingDecoration && <div className='heading-decoration'></div>}
            {paragraphs && paragraphs.map((value, index) =>
                <p className={`feature-details-with-searchbar-paragraph ${fontColorClass}`} key={`${componentName}_paragraph_${index}`} dangerouslySetInnerHTML={{ __html: value }} />
            )}
            {multipleSearch ? singleSearchComp && singleSearchComp.singleSearchBarId &&
                <div className="single-searchbar-section">
                    <SingleSearchBar
                        formId={singleSearchBarId}
                        searchButtonPlaceholder={searchButtonPlaceholder}
                        searchTextboxValue={searchTextboxValue}
                        setSearchTextboxValue={setSearchTextboxValue}
                        inputId={`${singleSearchBarId}_input`}
                        eventCreatedKeyDown={eventCreatedKeyDown}
                        eventTypeKeyDown={eventTypeKeyDown}
                        eventPage={eventPage}
                        eventTypeSearch={eventTypeSearch}
                        eventCreatedSearch={eventCreatedSearch}
                        eventCreatedOptionClick={eventCreatedOptionClick}
                        eventCreatedIconClick={eventCreatedIconClick}
                        type={singleSearchBarType}
                        singleSearchTypes={singleSearchTypes}
                        industry={industry}
                        showDropDownTextOnly={showDropDownTextOnly}
                        modalHeading={modalHeading}
                        modelCssClass={modelCssClass}
                        dropDownOptionOnClick={dropDownOptionOnClick}
                        mediaEqualVersionType={mediaEqualVersionType}
                        disableSearchIconClick={disableSearchIconClick}
                        applyLiveDormantFilter={applyLiveDormantFilter}
                        showSearchText={showSearchText}
                        inputBeforeIcon={inputBeforeIcon}
                    />
                </div>:
                 singleSearchComp && singleSearchComp.singleSearchBarId &&
                   <div className="single-searchbar-section">
                        <IsolatedSearchBar
                            formId={singleSearchBarId}
                            searchButtonPlaceholder={searchButtonPlaceholder}
                            searchTextboxValue={searchTextboxValue}
                            setSearchTextboxValue={setSearchTextboxValue}
                            inputId={`${singleSearchBarId}_input`}
                            eventCreatedKeyDown={eventCreatedKeyDown}
                            eventTypeKeyDown={eventTypeKeyDown}
                            eventPage={eventPage}
                            eventTypeSearch={eventTypeSearch}
                            eventCreatedSearch={eventCreatedSearch}
                            eventCreatedOptionClick={eventCreatedOptionClick}
                            eventCreatedIconClick={eventCreatedIconClick}
                            type={singleSearchBarType}
                            singleSearchTypes={singleSearchTypes}
                            multipleSearch={multipleSearch}
                            btnLink={btnLink}
                            userType={userType}
                            DropdownItemTag={DropdownItemTag}
                            optionOnClick={optionOnClick}
                            dropdownItemClassName={dropdownItemClassName}
                            showSearchText={showSearchText}
                            inputBeforeIcon={inputBeforeIcon}
                        />
                   </div>
                }
            {searchbarDividerLine && !imageUrl?.pngImageUrl && 
                <div className='heading-decoration w-100 feature-details-mobile-liner'></div>
            }
            {imageUrl?.pngImageUrl && <CustomMediaQuery><div className='feature-details-with-image'>
                    <picture>
                        <source srcSet={imageUrl.webpImageUrl} />
                        <img src={imageUrl.pngImageUrl} className="center-image lazyload" 
                        width={imageUrl.width ? imageUrl.width : "1031px"} 
                        height={imageUrl.height ? imageUrl.height : "339px"}
                        alt={imageUrl.alt ? imageUrl.alt : getAltAttribute(imageUrl.webpImageUrl)}/>
                    </picture>
                </div></CustomMediaQuery>}
            { informationSectionData && informationSectionData.componentName
                && <div className="information-section">
                    <p className="title">{informationSectionData.title}</p>
                    {
                        informationSectionData.paragraphs &&
                        informationSectionData.paragraphs.map((paragraph, index) =>
                            <p className="paragraph" key={`${informationSectionData.componentName}_paragraph_${index}`}>{paragraph}</p>
                        )
                    }
                    {informationSectionData.button &&
                        <button className="yellow-bg-btn" pomandae2e={informationSectionData.button.text} onClick={informationSectionData.button.onClick}>
                            {informationSectionData.button.text}
                        </button>
                    }
                </div>}
           {buttonComp.length > 0 && <div className='feature-details-with-searchbar-btn-section'>
                {buttonComp?.map?.((buttonCompObj)=>{
                    return (
                            buttonCompObj.componentName && (buttonCompObj?.url ?
                            <Link to={`${buttonCompObj?.url}`} target={buttonCompObj.target || `_blank`} key={buttonCompObj.componentName}><button className={`yellow-bg-btn ${buttonCompObj.btnBgType === "transparent" ? "feature-details-transparent-btn-bg" : ""}`}
                                onClick={buttonCompObj?.onClick}
                                pomandae2e={buttonCompObj.text}>{buttonCompObj.text}</button>
                            </Link>
                            :<button className={`yellow-bg-btn ${buttonCompObj.btnBgType === "transparent" ? "feature-details-transparent-btn-bg" : ""} ${buttonCompObj.className ? buttonCompObj.className : ""}`} key={buttonCompObj.componentName} onClick={buttonCompObj?.onClick} pomandae2e={buttonCompObj.text}>
                                {buttonCompObj.text}
                                {buttonCompObj.iconCssClassName ? <i className={`fa ${buttonCompObj.iconCssClassName} ml-2`} aria-hidden="true"></i> : null}
                            </button>)
                    )
                })}
            </div>}
            {homePageCardComp?.cardDataList?.length > 0 && <div className='feature-details-with-home-page-card-comp'>
                {homePageCardComp.heading && <p className='white-color-text feature-details-home-page-card-heading'>{homePageCardComp.heading}</p>}
                <div className='feature-details-with-home-page-card-container'>
                    <HomePageCardComponent
                            cardDataList={homePageCardComp.cardDataList}
                    />
                </div>
            </div>}
            {linkNote.text && linkNote.url ?
                <Link to={linkNote.url} onClick={() => linkNote.onClick(searchTextboxValue)} className={`link-note-text ${fontColorClass}`}>
                    <p>
                        <DynamicSvg className="feature-details-with-searchbar-link-logo" src="/assets/svgs/thunder_yellow2x.svg" alt="company search logo" />
                        <span className="link-bottom-border" pomandae2e={linkNote.text}>{linkNote.text}</span>
                    </p>
                </Link>
                : null
            }
            {textNote && <p className={`feature-details-with-searchbar-text-note ${fontColorClass}`} dangerouslySetInnerHTML={{ __html: textNote }}></p>}
        </div>
    )
})


export default FeatureDetailsWithSearchbar;

import React, { memo, useState } from 'react';
import MediaQuery from 'react-responsive';
import FeatureDetailsWithSearchbar from '../FeatureDetailsWithSearchbar/FeatureDetailsWithSearchbar';
import FeatureDetailsComponent from './FeatureDetailsComponent/FeatureDetailsComponent';
import './LeftSideImageRightSideText.css'

const CustomMediaQuery = ({ children, mediaQueryParamImg = "" }) => {
    let renderedUi = null;
    if(mediaQueryParamImg === "desktop") {
        renderedUi = <MediaQuery minWidth={992}>{children}</MediaQuery>       
    } else {
        renderedUi = <>{children}</>       
    }
    return renderedUi;
}

const LeftSideImageRightSideText = memo(function LeftSideImageRightSideText(props) {
    const {
        componentName = "",
        imageUrl = "",
        webPimageUrl = "",
        imageUrlMobileView="",
        imageWidth = "",
        imageHeight = "",
        featureDetailsArr = null,
        featureDetailsWithSearchData = {},
        page="",
        altTag="" ,
        mediaQueryParamImg = "",
        HeadingTag = "h2",
        isShowMoreFunc={},
        logoPath=""
    } = props;
    const {
        singleSearchComp = {},
        heading = "",
        paragraphs = [],
        linkNote = {},
        alignment = "",
        fontColorClass = "",
        headingSize = "",
        textNote = "",
        headingTag = "",
        multipleSearch = true,
        DropdownItemTag = "",
        optionOnClick = () => {},
        dropdownItemClassName = ""
    } = featureDetailsWithSearchData;
    const [isVisibleMoreContent, setIsVisibleMoreContent] = useState(false);

    const getAltAttribute = (imagePath) => {
        if(!imagePath) {
            return "";
        }
        const imageName = imagePath.split("/");
        const altTagAttribute = imageName[imageName.length - 1].split(".")[0];
        return `${altTagAttribute}_image`;
    }

    return (
        <div className="left-side-img-right-side-text" id={componentName}>
            <CustomMediaQuery mediaQueryParamImg={mediaQueryParamImg}><div className="left-side-image-parent">
                {imageUrlMobileView && <picture>
                    <img data-src={imageUrlMobileView}
                        className={`left-side-image lazyload`}
                        width={imageWidth ? imageWidth : "541"}
                        height={imageHeight ? imageHeight : "425"}
                        alt={altTag ? altTag : getAltAttribute(imageUrlMobileView)}
                    />
                </picture>}

            {(imageUrl || webPimageUrl) && <picture>
                    <source type="image/webp" data-srcset={webPimageUrl} />
                    <img data-src={imageUrl} className="left-side-image lazyload" 
                        width={imageWidth ? imageWidth : "541px"} 
                        height={imageHeight ? imageHeight : "424px"} 
                        alt={getAltAttribute(imageUrl)}
                    />
                </picture>}
            </div></CustomMediaQuery>
            {featureDetailsArr && featureDetailsArr.length ?
                <FeatureDetailsComponent dataArr={featureDetailsArr} componentName={componentName} logoPath={logoPath} isShowMoreFunc={isShowMoreFunc}
                    visibleFeatureDetailsBusinessPlan={isVisibleMoreContent ? "" : "show-how-pomanda-helps-content"} 
                    HeadingTag={HeadingTag}/>
                : <FeatureDetailsWithSearchbar
                    componentName={componentName}
                    singleSearchComp={singleSearchComp}
                    heading={heading}
                    paragraphs={paragraphs}
                    alignment={alignment}
                    fontColorClass={fontColorClass}
                    linkNote={linkNote}
                    headingSize={headingSize}
                    textNote={textNote}
                    headingTag={headingTag}
                    multipleSearch={multipleSearch}
                    DropdownItemTag={DropdownItemTag}
                    optionOnClick={optionOnClick}
                    dropdownItemClassName={dropdownItemClassName}
                />
            }
            {page === "business_landing_page" && <MediaQuery maxWidth={575}>
                <p onClick={()=>{setIsVisibleMoreContent(!isVisibleMoreContent)}} className='visible-business-content'>{isVisibleMoreContent ? "Read less" : "Read more"}</p>
            </MediaQuery>}
        </div>
    );
});

export default LeftSideImageRightSideText;